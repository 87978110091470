import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeM from '../views/mobile/Home.vue'


Vue.use(VueRouter)

// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const routes = [{
    path: '/',
    name: 'HomeM',
    component: HomeM
  },
  {
    path: '/join-us-m',
    name: 'join-us-m',
    component: () => import( /* webpackChunkName: "about" */ '../views/mobile/JoinUs.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/join-us',
    name: 'join-us',
    component: () => import( /* webpackChunkName: "about" */ '../views/JoinUs.vue')
  },
  {
    path: '/add-info',
    name: 'add-info',
    component: () => import( /* webpackChunkName: "about" */ '../views/Info.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
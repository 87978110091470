<template>
  <div class="header">
    <div class="logo">
      <img src="http://acc.sys-img.quhuo.net/site_front/site_logo.png" alt="" />
    </div>
    <div class="nav">
      <div class="nav-list" v-if="tab == 0" @click="changeTabEvent(1)">
        <div>加入我们</div>
        <img src="../../assets/right.png" alt="" />
      </div>
      <div class="nav-list" v-else @click="changeTabEvent(0)">
        <div>返回首页</div>
        <img src="../../assets/right.png" alt="" />
      </div>

      <!-- <div 
          v-for="(nav, index) in navs" 
          :key="index" class="nav-list" 
          v-if="index==tab"
          @click="changeTabEvent(index, nav)"
        >{{nav.name}}<img src="../../assets/right.png" alt="" /></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderMUI",
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    changeTabEvent(index) {
      this.tab = index;
      if (this.tab == 1) {
        this.$router.push("/join-us-m");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  width: 100%;
  height: 0.6rem;
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.04rem 0 rgba(251, 163, 105, 0.2);
  z-index: 10;

  .logo {
    width: 0.36rem;
    height: 0.36rem;

    img {
      width: 100%;
    }
  }

  .nav {
    display: flex;
    align-items: center;

    .nav-list {
      font-size: 0.12rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #ff6915;
      user-select: none;

      img {
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }
}
</style>

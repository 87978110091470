<template>
  <div class="main" @click="hidePop">
    <div class="code" @click.stop="doSomething">
      <img
        src="http://acc.sys-img.quhuo.net/site_front/site_gongzhonghao.png"
        alt=""
        v-if="imgType == 1"
      />
      <img
        src="http://acc.sys-img.quhuo.net/site_front/site_kefu.png"
        alt=""
        v-else
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CenterPopUI",
  props: {
    imgType: 0,
  },
  data() {
    return {};
  },
  methods: {
    hidePop() {
      this.$emit("hidePop", false);
    },
    doSomething() {
      return;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  position: fixed;
  top: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .code {
    width: 2.58rem;
    height: 2.58rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div id="app-pc" v-if="PC">
    <div style="height: 112px">
      <HeaderUI></HeaderUI>
    </div>
    <router-view />
  </div>
  <div v-else>
    <HeaderMUI></HeaderMUI>
    <router-view />
  </div>
</template>

<script>
import HeaderUI from "@/component/Header";
import FooterUI from "@/component/Footer";
import HeaderMUI from "@/component/mobile/Header";
export default {
  components: {
    HeaderUI,
    FooterUI,
    HeaderMUI,
  },
  data() {
    return {
      PC: true,
    };
  },
  mounted() {
    if (this._isMobile()) {
      this.PC = false;
    } else {
      this.$router.replace("/Home");
      this.PC = true;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
html {
  background-color: #ffffff;
}
#app-pc {
  background: #ffffff;
  position: relative;
}

.max-min-width {
  max-width: 1440px;
  min-width: 1170px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Medium, PingFang SC -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 100;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

/* 清浮动  */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  zoom: 1;
  /* for IE6 IE7 */
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 14px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
}
input :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
}
</style>

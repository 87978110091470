<template>
  <div class="Home">
    <div class="bgImg">
      <img src="http://acc.sys-img.quhuo.net/site/bg.png" alt="" />
    </div>
    <div class="fullPageOne">
      <div class="title-1">Make each day count </div>
      <div class="title-2">取伙</div>
      <div class="title-3">Z世代的活动聚集地</div>
      <div class="quhuoImg">
        <img src="http://acc.sys-img.quhuo.net/site_front/site_phone.png" alt="" />
      </div>
    </div>
    <div class="fullPageTwo">
      <div class="title-1">MAIN FEATURES</div>
      <div class="title-2">你的世界，有TA入伙。</div>
      <div class="title-3">就此燥起来！</div>
      <div class="swiperBox">
        <swiper ref="mcSwiper" class="echw-fb-swiper" :options="swiperOption">
          <swiper-slide class="echw-fb-swiper-slide">
            <div class="swiperContent">
              <div class="bannerImg">
                <img src="http://acc.sys-img.quhuo.net/site_front/site_new.png" />
              </div>
              <div class="bannerType">
                <img src="../../assets/mobile/icon_camera.png" alt="" />
                此刻
              </div>
              <div class="bannerDesc">
                <p>在不同的圈子里分享你不同动态</p>
                <p>你的踪迹每个人看起来或许都独一无二</p>
              </div>
            </div></swiper-slide
          >
          <swiper-slide class="echw-fb-swiper-slide"
            ><div class="swiperContent">
              <div class="bannerImg">
                <img src="http://acc.sys-img.quhuo.net/site_front/site_community.png" />
              </div>
              <div class="bannerType">
                <img src="../../assets/mobile/icon_emoticon.png" alt="" />
                圈子
              </div>
              <div class="bannerDesc">
                <p>不同的圈子，带你认识世界的多样。</p>
                <p>拓展城市社交圈，也是拓展你自己</p>
              </div>
            </div></swiper-slide
          >
          <swiper-slide class="echw-fb-swiper-slide"
            ><div class="swiperContent">
              <div class="bannerImg">
                <img src="http://acc.sys-img.quhuo.net/site_front/site_activity.png" />
              </div>
              <div class="bannerType">
                <img src="../../assets/mobile/icon_list.png" alt="" />
                活动
              </div>
              <div class="bannerDesc">
                <p>一目了然的组局，随时随地</p>
                <p>找一个贴合你心境的去处</p>
              </div>
            </div></swiper-slide
          >
        </swiper>
        <div class="swiper-button-prev" slot="button-prev">
          <img src="../../assets/mobile/circle-left.png" alt="" />
        </div>
        <div class="swiper-button-next" slot="button-next">
          <img src="../../assets/mobile/circle-right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="fullPageThree">
      <div class="title-1">MAKE EACH DAY COUNT</div>
      <div class="title-2">就此开启取伙生活</div>
      <div class="title-2">让世界融入更多的你。</div>
      <div class="quhuoImg">
        <img src="http://acc.sys-img.quhuo.net/site_front/site_xiaochengxu.png" alt="" />
      </div>
      <div class="btm btm-1" @click="showPop(0)">微信公众号</div>
      <div class="btm btm-2" @click="showPop(1)">取伙客服</div>
      <div class="iseeImg">
        <img src="http://acc.sys-img.quhuo.net/site/iseeLOGO.png" alt="" />
      </div>
      <div class="footer">
        <div>公司地址：深圳市南山区朗山路11号同方信息港B座902</div>
        <div>电话：0755-83208393</div>
        <div>邮箱：business@isee.work</div>
        </br>
        <div>
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            style="color: rgba(255, 255, 255, 0.6)"
            >粤ICP备19128020号</a
          >
        </div>
           <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902002846"
            style="color: rgba(255, 255, 255, 0.6)"
            >
            <img class="gonganwangbei" src="https://acc.sys-img.quhuo.net/site/gonganwangbei.png" alt="">
            粤公网安备 44030902002846号</a
          >
        </div>
        <div>增值电信业务经营许可证:粤B2-2019208</div>
        <div>©深圳市钻木信息技术有限公司 2022.All Rights Reserved.</div>
      </div>
    </div>
    <div v-show="showCodePop">
      <CenterPopUI imgType="imgType" @hidePop="hidePopEvent"></CenterPopUI>
    </div>
  </div>
</template>

<script>
import CenterPopUI from "@/component/mobile/CenterPop";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    CenterPopUI,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      showCodePop: false,
      imgType: 0,
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    showPop(type) {
      this.showCodePop = true;
      this.imgType = type;
    },
    hidePopEvent(data) {
      console.log(data);
      this.showCodePop = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
}
.swiper-button-prev,
.swiper-button-next {
  width: 0.26rem;
  height: 0.26rem;
  top: 47%;
  img {
    width: 100%;
    height: 100%;
  }
}
.swiper-button-next {
  right: 0.28rem;
}
.swiper-button-prev {
  left: 0.28rem;
}
.swiperBox {
  width: 2.4rem;
  margin-bottom: 0.49rem;
}
// swiper
.echw-fb-swiper {
  width: 100%; // 外层dev有宽度
  height: 100%;
}

// swiper-slide -- 无论是不是当前slide，宽高保持不变
.echw-fb-swiper-slide {
  width: 1.93rem;
  .swiperContent {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    .bannerType {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #212128;
      font-size: 0.15rem;
      margin-bottom: 0.14rem;
      img {
        width: 0.15rem;
        height: 0.15rem;
        margin-right: 0.05rem;
      }
    }
    .bannerDesc {
      color: #999;
      font-size: 0.13rem;
      p {
        text-align: center;
      }
    }
  }
  // 图片居左
  .bannerImg {
    border: 0.04rem solid #fff;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(251, 163, 105, 0.3);
    margin-bottom: 0.38rem;
    width: 2.01rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 0;
    }
  }
}
.Home {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  top: 0.6rem;
  background: #fff;
  .bgImg {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.95rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .fullPageOne {
    margin-top: 0.82rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .title-1 {
      color: #bababe;
      font-size: 0.1rem;
    }
    .title-2 {
      color: #212128;
      font-size: 0.32rem;
      margin-top: 0.06rem;
    }
    .title-3 {
      color: #212128;
      font-size: 0.15rem;
      margin-top: 0.08rem;
    }
    .quhuoImg {
      width: 1.91rem;
      margin-top: 0.25rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .fullPageTwo {
    margin-top: 0.63rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .title-1 {
      color: #bababe;
      font-size: 0.1rem;
    }
    .title-2 {
      color: #212128;
      font-size: 0.26rem;
      margin-top: 0.06rem;
    }
    .title-3 {
      color: #212128;
      font-size: 0.15rem;
      margin-top: 0.08rem;
      margin-bottom: 0.24rem;
    }
    .quhuoImg {
      width: 1.91rem;
      margin-top: 0.25rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .fullPageThree {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #FF6915;
    .title-1 {
      color: #fff;
      font-size: 0.1rem;
      margin-top: 0.66rem;
      margin-bottom: 0.18rem;
    }
    .title-2 {
      color: #fff;
      font-size: 0.26rem;
      margin-top: 0.06rem;
    }
    .quhuoImg {
      width: 2.13rem;
      margin-top: 0.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .btm {
      width: 0.99rem;
      height: 0.3rem;
      background: #fff;
      border-radius: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      color: #FF6915;
      font-size: 0.11rem;
    }
    .btm-1 {
      margin-top: 0.37rem;
      margin-bottom: 0.17rem;
    }
    .iseeImg {
      width: 0.98rem;
      margin-top: 0.4rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.09rem;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 0.22rem;
      margin-bottom: 0.19rem;
    }
    .gonganwangbei{
      display: inline-block;
      position: relative;
      width: 0.12rem;
      height: 0.12rem;
      top: 0.02rem; 
    }
  }
}
</style>

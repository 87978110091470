<template>
  <div class="footer max-min-width">
    <div class="footer-left">
      <div class="logo-vessel">
        <div class="logo">
          <img src="http://acc.sys-img.quhuo.net/site_front/site_logo.png" alt="">
        </div>
        <div class="isee">
          <img src="http://acc.sys-img.quhuo.net/site/isee.png" alt="">
        </div>
      </div>
      <div class="tip">
        <div>公司地址：深圳市南山区朗山路11号同方信息港B座902 &nbsp;&nbsp;
          电话：0755-83208393 &nbsp;&nbsp; 邮箱：business@isee.work</br>
          <a target="_blank" href="https://beian.miit.gov.cn/" style="color:#999">粤ICP备19128020号</a>&nbsp;&nbsp;
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902002846" style="color:#999"><img class="gonganwangbei" src="https://acc.sys-img.quhuo.net/site/gonganwangbei.png" alt="">粤公网安备 44030902002846号</a>
          &nbsp;&nbsp;增值电信业务经营许可证:粤B2-2019208</br>
          ©深圳市钻木信息技术有限公司 2022.All Rights Reserved.
        </div>
      </div>
    </div>
    <div class="footer-right">
      <div class="code">
        <div class="code-img">
          <img src="http://acc.sys-img.quhuo.net/site_front/site_gongzhonghao.png" alt="">
        </div>
        <div>取伙公众号</div>
      </div>
      <div class="code">
        <div class="code-img">
          <img src="http://acc.sys-img.quhuo.net/site_front/site_kefu.png" alt="">
        </div>
        <div>取伙客服</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterUI',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .footer{
    height: 266px;
    padding: 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img{
      width: 100%;
    }

    .footer-left{

      .logo-vessel{
        margin-bottom:20px;
        display: flex;

        .logo{
          width: 50px;
          margin-right: 20px;
        }

        .isee{
          width: 120px;
          height: 50px;
        }
      }


      .tip{
        color: #999999;
        font-size: 12px;
        line-height: 24px;
      }
    }
    .gonganwangbei{
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
      top: 2px; 
      margin-right:2px;
    }
    .footer-right{
      color: #212128;
      font-size: 12px;
      text-align: center;
      display: flex;

      .code{

        .code-img{
          width: 90px;
          height: 90px;
          background: #eeeeee;
          margin-bottom: 3px;
        }
      }

      .code:nth-child(1){
        margin-right: 30px;
      }
    }
  }
</style>

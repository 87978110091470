<template>
  <div class="Home">
    <div class="quhuo max-min-width clearfix">
      <div class="isee">iSEE</div>
      <div class="quhuo-v">
        <div style="margin-top:10vh;">
          <div class="title-1">Make each day count </div>
          <div class="title-2">取伙</div>
          <div class="title-3">Z世代的活动聚集地</div>
          <div class="weixin">
            <div style="background:#fff;">
              <div class="tip">开始取伙</div>
              <img src="http://acc.sys-img.quhuo.net/site_front/site_xiaochengxu.png" alt="">
            </div>
          </div>
        </div>
        <div class="phone">
          <img style="width:350px;" src="http://acc.sys-img.quhuo.net/site_front/site_phone.png" alt="">
        </div>

        <div class="round">
          <img src="http://acc.sys-img.quhuo.net/site/round.png" alt="">
        </div>
      </div>
    </div>
    <div style="background:#FAFAFA;">
      <div class="example-vessel max-min-width">
        <div class="title-vessel">
          <div class="title-1">MAIN FEATURES</div>
          <div  class="title-2">你的世界，有TA入伙。</div>
          <div  class="title-3">就此燥起来！</div>
        </div>

        <div class="example">
          <div class="list">
            <img src="http://acc.sys-img.quhuo.net/site_front/site_new.png" alt="">
            <div>
              <div class="title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconicon_camera"></use>
                </svg>
                此刻
              </div>
              <div class="text">
                在不同的圈子里分享你不同动态</br>
                你的踪迹每个人看起来或许都独一无二
              </div>
            </div>
          </div>
          <div class="list">
            <img src="http://acc.sys-img.quhuo.net/site_front/site_community.png" alt="">
            <div>
              <div class="title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconicon_emoticon"></use>
                </svg>
                圈子 
              </div>
              <div class="text">
                不同圈子的人，会让你对世界有不一样的认识</br>
                拓展城市社交圈，也是拓展你自己
              </div>
            </div>
          </div>
          <div class="list">
            <img src="http://acc.sys-img.quhuo.net/site_front/site_activity.png" alt="">
            <div>
              <div class="title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconicon_list"></use>
                </svg>
                活动
              </div>
              <div class="text">
                一目了然的组局，随时随地</br>
                找一个贴合你心境的去处
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div style="background: #FF6915;">
      <div class="max-min-width">
        <div class="code-vessel">
          <div>
            <div style="font-size:16px;margin-bottom:20px;">MAKE EACH DAY COUNT</div>
            <div style="font-size:40px;line-height:60px;">
              取伙</br>
              打开有趣的一天！
            </div>
          </div>
          <div>
            <img src="http://acc.sys-img.quhuo.net/site_front/site_xiaochengxu.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <FooterUI></FooterUI>
  </div>
</template>

<script>
import FooterUI from "@/component/Footer";
export default {
  components: {
    FooterUI
  },
  name: "Home",
  props: {
    msg: String,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Home {



  .quhuo{
    height: 800px;
    position: relative;

    .quhuo-v{
      padding: 0 230px 80px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

    .round{
      width: 410px;
      position: absolute;
      top: -112px;
      right: 0;
      z-index: -10;


      img{
        width: 100%;
      }
    }

    .isee{
      color: #FFFBF7;
      font-size: 250px;
      position: absolute;
      line-height: 250px;
      letter-spacing: 10px;
      left: 100px;
      top: 0;
    }

    .phone{

      img{
        box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
      }
    }

    .weixin{
      padding: 60px 10px 10px;
      background-color: #FF6915;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      width: 200px;
      margin-top: 55px;
      position: relative;
      box-shadow: 0px 10px 20px 0px rgba(251, 163, 105, 0.5);

      .tip{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
      }

      img{
        width: 180px;
      }
    }

    .title-1{
      color: #C2C2C6;
      font-size: 16px;
    }
    .title-2{ 
      color: #212128;
      font-size: 60px;
      line-height: 110px;
    }
    .title-3{
      color: #212128;
      font-size: 40px;
    }
  }
  
  .example-vessel{
    padding: 70px 137px;

    .title-vessel {
      text-align: center;

      .title-1 {
        color: #bababe;
        font-size: 16px;
      }
      .title-2 {
        color: #212128;
        font-size: 40px;
        line-height: 100px;
      }
      .title-3 {
        color: #25242e;
        font-size: 20px;
      }
    }

    .example {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .list {
        color: #24242e;
        font-size: 20px;
        font-weight: 600;

        img {
          width: 272px;
          box-shadow: 0px 0px 30px 1px rgba(251, 163, 105, 0.5);
        }

        .text {
          color: #999999;
          font-size: 14px;
          line-height: 24px;
          font-weight: normal;
          text-align: center;
        }

        .title {
          margin: 30px 0 10px;
          text-align: center;
        }
      }
    }
  }

  .code-vessel{
    
    padding: 125px 290px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;

    img{
      width: 173px;
    }
  }
}
</style>

<template>
  <div class="header" :class="[bg ? '_header' : '']">
    <div class="max-min-width header-vessel">
      <div class="logo">
        <router-link to="/">
          <img
            src="http://acc.sys-img.quhuo.net/site_front/site_logo.png"
            alt=""
          />
        </router-link>
      </div>
      <div class="nav">
        <div
          v-for="(nav, index) in navs"
          :key="index"
          class="nav-list"
          :class="tab == index ? '_nav-list' : ''"
          @click="changeTabEvent(index, nav)"
        >
          {{ nav.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderUI",
  data() {
    return {
      bg: false,
      tab: 0,
      navs: [
        {
          name: "首页",
          path: "/Home",
        },
        {
          name: "加入我们",
          path: "/join-us",
        },
        {
          name: "圈子助手",
          path: "/ERP",
        },
      ],
    };
  },
  created() {
    this.bg = !this.$route.path == "/";
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log(to);
        if (to.path == "/") {
          this.tab = 0;
        } else if (to.path == "/join-us") {
          this.tab = 1;
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeTabEvent(index, navData) {
      if (this.tab == index) {
        return;
      }

      this.tab = index;
      if (navData.name == "圈子助手") {
        window.open("https://qz.quhuo.net/", "_blank");
      } else {
        this.$router.push(navData.path);
      }
    },
  },
  mounted() {
    let that = this;
    window.onscroll = function (e) {
      var scrollTop = document.documentElement.scrollTop;
      if (scrollTop < 150 && that.$route.path == "/") {
        that.bg = false;
      } else {
        that.bg = true;
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
._header {
  background-color: #ffffff;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // border-bottom: 1px solid #f1f1f1;
  z-index: 1000;

  .header-vessel {
    padding: 0 120px;
    display: flex;
    align-items: center;
    height: 112px;
  }

  .logo {
    width: 55px;
    height: 55px;
    margin-right: 40px;

    img {
      width: 100%;
    }
  }

  .nav {
    display: flex;
    align-items: center;

    .nav-list {
      height: 38px;
      width: 116px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #212128;
      user-select: none;

      &:hover {
        color: #ff6915;
      }
    }

    ._nav-list {
      border: 1px solid #ff6915;
      border-radius: 50px;
      color: #ff6915;
    }
  }
}
</style>

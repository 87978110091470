var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Home"},[_vm._m(0),_c('div',{staticStyle:{"background":"#FAFAFA"}},[_c('div',{staticClass:"example-vessel max-min-width"},[_vm._m(1),_c('div',{staticClass:"example"},[_c('div',{staticClass:"list"},[_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_new.png","alt":""}}),_c('div',[_c('div',{staticClass:"title"},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconicon_camera"}})]),_vm._v(" 此刻 ")]),_vm._m(2)])]),_c('div',{staticClass:"list"},[_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_community.png","alt":""}}),_c('div',[_c('div',{staticClass:"title"},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconicon_emoticon"}})]),_vm._v(" 圈子 ")]),_vm._m(3)])]),_c('div',{staticClass:"list"},[_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_activity.png","alt":""}}),_c('div',[_c('div',{staticClass:"title"},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconicon_list"}})]),_vm._v(" 活动 ")]),_vm._m(4)])])])])]),_vm._m(5),_c('FooterUI')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quhuo max-min-width clearfix"},[_c('div',{staticClass:"isee"},[_vm._v("iSEE")]),_c('div',{staticClass:"quhuo-v"},[_c('div',{staticStyle:{"margin-top":"10vh"}},[_c('div',{staticClass:"title-1"},[_vm._v("Make each day count ")]),_c('div',{staticClass:"title-2"},[_vm._v("取伙")]),_c('div',{staticClass:"title-3"},[_vm._v("Z世代的活动聚集地")]),_c('div',{staticClass:"weixin"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"tip"},[_vm._v("开始取伙")]),_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_xiaochengxu.png","alt":""}})])])]),_c('div',{staticClass:"phone"},[_c('img',{staticStyle:{"width":"350px"},attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_phone.png","alt":""}})]),_c('div',{staticClass:"round"},[_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site/round.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-vessel"},[_c('div',{staticClass:"title-1"},[_vm._v("MAIN FEATURES")]),_c('div',{staticClass:"title-2"},[_vm._v("你的世界，有TA入伙。")]),_c('div',{staticClass:"title-3"},[_vm._v("就此燥起来！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v(" 在不同的圈子里分享你不同动态"),_c('br'),_vm._v(" 你的踪迹每个人看起来或许都独一无二 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v(" 不同圈子的人，会让你对世界有不一样的认识"),_c('br'),_vm._v(" 拓展城市社交圈，也是拓展你自己 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v(" 一目了然的组局，随时随地"),_c('br'),_vm._v(" 找一个贴合你心境的去处 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#FF6915"}},[_c('div',{staticClass:"max-min-width"},[_c('div',{staticClass:"code-vessel"},[_c('div',[_c('div',{staticStyle:{"font-size":"16px","margin-bottom":"20px"}},[_vm._v("MAKE EACH DAY COUNT")]),_c('div',{staticStyle:{"font-size":"40px","line-height":"60px"}},[_vm._v(" 取伙"),_c('br'),_vm._v(" 打开有趣的一天！ ")])]),_c('div',[_c('img',{attrs:{"src":"http://acc.sys-img.quhuo.net/site_front/site_xiaochengxu.png","alt":""}})])])])])
}]

export { render, staticRenderFns }